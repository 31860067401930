<template>
    <div class="d-block">
        <vs-navbar v-model="active" padding-scroll shadow>
            <template #left>
                <router-link
                    to="/"
                    class="ml-mx-3 ml-1 mr-1"
                    aria-label="Inicio"
                >
                    <picture>
                        <source
                            srcset="/img/logos/complete_logo.png"
                            class="h-10 w-auto"
                            width="180"
                            height="113"
                            media="(min-width:900px)"
                            x-content-type-options="nosniff"
                        />
                        <img
                            src="/img/logos/logo.png"
                            :alt="`Icono ${$siteTitle}`"
                            :title="`Icono ${$siteTitle}`"
                            class="h-10 w-auto"
                        />
                    </picture>
                </router-link>
            </template>

            <template #right>
                <div
                    class="d-flex w-100 justify-content-end align-items-center"
                >
                    <vs-button
                        v-if="accessToken"
                        class="font-weight-bold btn-a ml-md-2 ml-1"
                        aria-label="cerrar sesión"
                        border
                        @click="logout"
                    >
                        Cerrar Sesión
                    </vs-button>
                </div>
            </template>
        </vs-navbar>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "NavbarApp",
    components: {},
    data: () => ({
        active: true
    }),
    computed: {
        ...mapState("auth", ["accessToken"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    methods: {
        ...mapActions("auth", ["signOut"]),
        async logout() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "¿ Deseas cerrar tu sesión ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#02913f",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });

            if (dialogResult.isConfirmed) {
                this.signOut();
                this.$router.push("/login");
            }
        }
    }
};
</script>
