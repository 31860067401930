<template>
    <main class="h-screen w-screen">
        <navbar-simple />
        <div id="onboarding-container" class="container pt-5">
            <div class="col-12 h-20"></div>
            <steps-viewer
                v-if="currentStep !== -1"
                :steps="$data.$steps"
                :step="currentStep"
            />
            <router-view></router-view>
            <div class="col-12 h-20"></div>

            <div class="config p-2 border-top border-left border-success">
                <change-theme />
            </div>
        </div>
    </main>
</template>

<script>
import ChangeTheme from "@/components/layout/ChangeTheme.vue";
import NavbarSimple from "@/components/layout/NavbarSimple.vue";
import StepsViewer from "@/components/utils/StepsViewer.vue";

export default {
    name: "LayoutOnboarding",
    components: {
        NavbarSimple,
        ChangeTheme,
        StepsViewer
    },
    data: () => ({
        currentStep: 0,
        $steps: [
            { name: "Crea Tu tienda" },
            { name: "Carga inventario" },
            { name: "Registra tus ventas" }
        ]
    }),
    computed: {
        currentPath() {
            return this.$route.path;
        }
    },
    watch: {
        currentPath(value) {
            this.validatePath(value);
        }
    },
    created() {
        this.$watch(
            () => this.$route,
            () => {
                document
                    .getElementById("onboarding-container")
                    .scrollIntoView({ behavior: "smooth" });
            },
            {
                deep: true
            }
        );
    },
    mounted() {
        this.validatePath(this.$route.path);
    },
    methods: {
        validatePath(value) {
            const fns = {
                "/onboarding": -1,
                "/onboarding/store": 0,
                "/onboarding/inventories": 1,
                "/onboarding/order": 2,
                "/onboarding/finish": 3
            };

            this.currentStep = fns[value];
        }
    }
};
</script>
